// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-download-brochure-tsx": () => import("./../../../src/pages/download-brochure.tsx" /* webpackChunkName: "component---src-pages-download-brochure-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-medical-care-professionals-tsx": () => import("./../../../src/pages/medical-care-professionals.tsx" /* webpackChunkName: "component---src-pages-medical-care-professionals-tsx" */),
  "component---src-pages-mortgage-advice-apply-tsx": () => import("./../../../src/pages/mortgage-advice/apply.tsx" /* webpackChunkName: "component---src-pages-mortgage-advice-apply-tsx" */),
  "component---src-pages-mortgage-advice-index-tsx": () => import("./../../../src/pages/mortgage-advice/index.tsx" /* webpackChunkName: "component---src-pages-mortgage-advice-index-tsx" */),
  "component---src-pages-parinity-fire-tsx": () => import("./../../../src/pages/parinity-fire.tsx" /* webpackChunkName: "component---src-pages-parinity-fire-tsx" */),
  "component---src-pages-pathways-tsx": () => import("./../../../src/pages/pathways.tsx" /* webpackChunkName: "component---src-pages-pathways-tsx" */),
  "component---src-pages-personal-advice-tsx": () => import("./../../../src/pages/personal-advice.tsx" /* webpackChunkName: "component---src-pages-personal-advice-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-testimonials-tsx": () => import("./../../../src/pages/testimonials.tsx" /* webpackChunkName: "component---src-pages-testimonials-tsx" */)
}

